<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false"
      @addEvent="$router.push({ name: 'addMerchant' })">
      {{ $t('auth.continueInfo') }}
    </dashboard-page-title>

    <form @submit.prevent="save">
      <b-row>
        <b-col md="8">
          <div>
            <b-card class="iq-border-radius-10 mb-3">
              <template v-slot:header>
                <h5 class="text-primary"><i class="las la-file-alt"></i>{{ $t('auth.continueInfo') }}</h5>
              </template>
              <b-row>
                <b-col lg="6">
                  <input-form class="mb-3" v-model="driverInfo.name" validate="required" :name="$t('auth.fullName')"
                    :placeholder="$t('auth.fullName')" :label="$t('auth.fullName')" />
                </b-col>
                <b-col lg="6">
                  <div class="d-flex mb-3">
                    <input-form class="mb-3 flex-1 flex-grow-1" v-model="driverInfo.phone"
                      :validate="'required|numeric'" :name="$t('auth.phoneNumber')"
                      :placeholder="$t('auth.phoneNumber')" :label="$t('auth.phoneNumber')" />
                  </div>
                </b-col>
                <b-col lg="6">
                  <input-form class="mb-3" v-model="driverInfo.idNumber"
                    :validate="{ required: true, numeric: true, digits: 10, regex: /^[1-2][0-9]*$/ }"
                    :name="$t('auth.IdNumber')" :placeholder="$t('auth.IdNumber')" :label="$t('auth.IdNumber')" />
                </b-col>
                <b-col lg="6">
                  <input-form class="mb-3" v-model="driverInfo.email" :validate="'required'" :name="$t('auth.email')"
                    :disabled="false" :placeholder="$t('auth.email')" :label="$t('auth.email')" type="email" />
                </b-col>
              </b-row>
            </b-card>
            <b-card class="iq-border-radius-10 mb-3">
              <template v-slot:header>
                <h5 class="text-primary"><i class="las la-image"></i> {{ $t('auth.images') }}</h5>
              </template>
              <b-row v-if="driverInfo.status === 'processing'">
                <b-col md="12" class="mb-4" v-for="(doc, key) in combinedDocs" :key="key">
                  <cropper-images :label="doc.name" nameOfImage="image.jpg" @cropper-save="updateImageCompined($event, doc, key)"
                    :progressLoading="progressIdImage" :multi="false" :imageUrl="doc.url" />
                </b-col>
              </b-row>
              <b-row v-else-if="driverInfo.status === 'pending'">
                <b-col md="12" class="mb-4" v-for="(doc, key) in combinedDocs" :key="key">
                  <cropper-images :label="doc.name" nameOfImage="image.jpg" @cropper-save="updateImageCompined($event, doc, key)"
                    :progressLoading="progressIdImage" :multi="false" :imageUrl="doc.url" />
                </b-col>
              </b-row>
            </b-card>

            <!-- <b-card class="iq-border-radius-10 mb-3">
              <template v-slot:header>
                <h5 class="text-primary"><i class="las la-store"></i>{{ $t('auth.vehicleDescription') }}</h5>
              </template>
              <b-row>
                <b-col md="12">
                  <textarea-form :placeholder="$t('auth.vehicleDescription')" v-model="driverInfo.carDescription"
                    :name="$t('auth.vehicleDescription')" validate="required"></textarea-form>
                </b-col>
              </b-row>
            </b-card> -->
          </div>
        </b-col>
        <b-col md="3">
          <b-card class="iq-border-radius-10 mb-3 main-actions">
            <template v-slot:header>
              <h5 class="text-primary">
                <i class="las la-save"></i>
                {{ $t('auth.publish') }}
              </h5>
            </template>
            <div v-if="loadingSubmitForm">
              <b-button variant="primary" class="w-100" disabled>
                <spinner-loading text="يتم الحفظ"></spinner-loading>
              </b-button>
            </div>
            <div class="d-flex justify-content-end gap_1" v-else>
              <b-button variant="primary" class="w-100" type="submit"> {{ $t('auth.save') }} </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import transportationServices from '@/modules/Driver/settings/services/settings'

export default {
  mixins: [],
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      requiredDocuments: [],
      driverInfo: {
        name: '',
        phone: '',
        email: '',
        idNumber: '',
        address: '',
        driver_docs: [],
        carDescription: '',
        countryCode: ''
      },
      loadingSubmitForm: false,
      loadingGallery: 0,
      removeLoadingUi: false,
      progressIdImage: 0,
      combinedDocs: []
    }
  },
  methods: {
    getRequiredDocuments () {
      transportationServices.getRequirementsDocuments().then(response => {
        this.requiredDocuments = response.data.data

        const combinedDocs = this.requiredDocuments.map(doc => {
          const driverDoc = this.driverInfo.driver_docs.find(d => d.document.id === doc.id)
          return {
            ...doc,
            url: driverDoc ? driverDoc.url : null // Add URL if it exists
          }
        })
        this.combinedDocs = combinedDocs
      })
    },
    getInfo () {
      transportationServices.getMerchantInfo().then(res => {
        // this.storeActive.status = res.data.status
        this.driverInfo.name = res.data.name
        this.driverInfo.address = res.data.address
        this.driverInfo.driver_docs = res.data.driverDocs
        this.driverInfo.carDescription = res.data.carDescription
        this.driverInfo.countryCode = res.data.countryCode
        this.driverInfo.phone = res.data.phone
        this.driverInfo.email = res.data.email
        this.driverInfo.idNumber = res.data.idNumber
        this.driverInfo.status = res.data.status

        this.getRequiredDocuments()
      })
    },
    updateImageCompined (file, doc, key) {
      const formData = new FormData()
      formData.append('image_name', file.imageInfo.name)
      formData.append('directory', 'Drivers')
      formData.append('file', file.image)
      formData.append('app_name', 'transportation')
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressIdImage = percent
        }
      }
      const fileExist = this.combinedDocs.find((ele) => {
        return ele.id === doc.id
      })
      this.commonUploadFiles(formData, options).then(res => {
        if (fileExist) {
          this.$set(this.combinedDocs, key, { ...doc, id: doc.id, url: res.data.url })
        } else {
          console.log('test no')
          this.combinedDocs.push({ document: { id: doc.id }, url: res.data.url })
        }
        this.showSuccessUploadFile()
      })
    },
    save () {
      this.loadingSubmitForm = true
      // Check if all required documents have images
      const missingImages = this.combinedDocs.some(doc => !doc.url)
      if (missingImages) {
        this.loadingSubmitForm = false
        core.showSnackbar('error', 'add required fields') // Display error message
        return
      }

      const testNewData = this.combinedDocs.map((ele) => ({ documentId: ele.id, url: ele.url }))

      transportationServices.uploadRequiredDocsFiles({ ...this.driverInfo, documents: testNewData, status: 'processing' }).then(res => {
        core.showSnackbar('success', res.data.message)
        // navigate if status of user is PENDING or processing
        this.$router.push({ path: '/driver/processing' })
      }).finally(() => {
        this.loadingSubmitForm = false
      })
    }
  },
  created () {
    this.getInfo()
  }
}
</script>
<style>
.phone-input .form-control {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.code-input {
  margin-top: 34px;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 9px !important;
  border-top-left-radius: 9px !important;
}

.main-actions {
  position: sticky !important;
  top: 0px;
}

.deleteLabelButton {
  position: absolute;
  left: 10px;
  top: -30px;
}
</style>
